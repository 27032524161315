





























import { Observer } from 'mobx-vue'
import { Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import ClaimerDetailOwnerViewModel from '../viewmodels/claimer-detail-owner-viewmodel'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

@Observer
@Component({
  components: {}
})
export default class EditCoverUrlDialog extends Vue {
  @Inject() vm!: ClaimerDetailOwnerViewModel
  @Prop({ default: false }) state
  coverUrl = ''

  async save() {
    try {
      if (!(this.$refs.form as any).validate()) return
      this.vm.changeEditDialogLoading(true)
      await this.vm.onChangeCoverUrl(this.coverUrl)
      snackController.success('Cover url updated successfully')
      this.vm.requestEditCoverUrlDialog(false)
    } catch (e) {
      snackController.error(e.message || e.msg)
    } finally {
      this.vm.changeEditDialogLoading(false)
    }
  }

  @Watch('state', { immediate: true }) changeState(value) {
    if (!value) {
      if (this.$refs.form) (this.$refs.form as any).reset()
    } else {
      this.coverUrl = this.vm.coverUrl
    }
  }
}
